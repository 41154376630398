import React, { useEffect } from "react";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { initializeFb } from "../data/initializeFb";

initializeFb();

export const Auth = ({ user }) => {
  const router = useHistory();
  useEffect(() => {
    if (user) {
      router.push("/");
    }
  }, [router, user]);
  return (
    <button
      onClick={() =>
        firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
      }
    >
      Sign in with Google
    </button>
  );
};
