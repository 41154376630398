import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { initializeFb } from "../data/initializeFb";
import { Auth } from "./Auth";
import { Home } from "./Home";

initializeFb();

function App() {
  const [user, setUser] = useState();
  useEffect(() => {
    return firebase.auth().onAuthStateChanged(setUser)
  }, [])
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home {...{user}} />
        </Route>
        <Route path="/auth">
          <Auth {...{user}} />
        </Route>
        <Route path="*">404 Not found</Route>
      </Switch>
    </Router>
  );
}

export default App;
