import React from "react";
import styled from "styled-components";
import { Item } from "./Item";

const Root = styled.div``;

export const ToBuyList = ({ toBuy, items, _ref }) => {
  return (
    <Root>
      {toBuy.map((itemId) => {
        const item = items[itemId];
        return <Item key={itemId} {...{ item, items, toBuy, _ref }} />;
      })}
    </Root>
  );
};
