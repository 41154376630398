import React from "react";
import {
  HiddenChars,
  Input,
  InputOverlay,
  InputWrapper,
} from "./AddToBuyTextInput.styles";

export const AddToBuyTextInput = ({
  inputProps,
  inputValue,
  topSuggestionName,
}) => {
  let overlayHidden = "";
  let overlayVisible = "";
  const topSuggestionMatches = topSuggestionName
    ?.toLowerCase()
    ?.startsWith(inputValue?.toLowerCase());
  const shouldShowOverlay = inputValue && topSuggestionMatches;
  if (shouldShowOverlay) {
    const overlayValue = topSuggestionName.replace(
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi,
      ""
    );
    overlayHidden = inputValue;
    overlayVisible = overlayValue.substring(inputValue.length);
  }
  return (
    <InputWrapper>
      <InputOverlay>
        <HiddenChars>{overlayHidden}</HiddenChars>
        {overlayVisible}
      </InputOverlay>
      <Input {...inputProps} />
    </InputWrapper>
  );
};
