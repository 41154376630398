import React, { useEffect, useRef, useState } from "react";
import { useCombobox } from "downshift";
import { OptionsList } from "./OptionsList";
import { onOutsideClick } from "../../utils/onOutsideClick";
import { useIsMobileWidth } from "../mediaStyle";
import {
  Combobox,
  SearchIcon,
  SearchIconSection,
  ToggleButton,
} from "./AddToBuyInput.styles";
import { getOptions, sortItems } from "./getOptions";
import { AddToBuyTextInput } from "./AddToBuyTextInput";
import { submitItem } from "./submitItem";

export const AddToBuyInput = ({ items, toBuy, _ref, defaultInput }) => {
  const [inputItems, setInputItems] = useState(
    sortItems(Object.values(items), toBuy)
  );
  const isMobile = useIsMobileWidth();
  const [isOpen, setIsOpen] = useState(!isMobile);
  const open = () => setIsOpen(true);
  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    inputValue,
    setHighlightedIndex,
    getItemProps,
    setInputValue,
    selectItem,
  } = useCombobox({
    items: inputItems,
    defaultInputValue: defaultInput || "",
    onInputValueChange: (e) => {
      open();
      const { inputValue } = e;
      if (
        ![
          useCombobox.stateChangeTypes.FunctionOpenMenu,
          useCombobox.stateChangeTypes.ItemClick,
          useCombobox.stateChangeTypes.InputKeyDownEnter,
        ].includes(e.type)
      ) {
        setHighlightedIndex(0);
        setInputItems(
          getOptions({ inputItems: Object.values(items), inputValue, toBuy })
        );
      }
    },
    itemToString: (i) => i?.name || "",
    onSelectedItemChange: (event) => {
      setTimeout(() => {
        setInputValue("");
        selectItem("");
        const { type, selectedItem } = event;

        if (
          type === useCombobox.stateChangeTypes.FunctionOpenMenu ||
          type === useCombobox.stateChangeTypes.ItemClick ||
          type === useCombobox.stateChangeTypes.InputKeyDownEnter
        ) {
          submitItem({ items, toBuy, _ref, selectedItem })
        }
      }, 300);
    },
  });

  useEffect(() => {
    setInputItems(getOptions({ inputItems: Object.values(items), toBuy }));
  }, [items, toBuy]);

  useEffect(() => {
    setIsOpen(!isMobile);
  }, [isMobile]);

  const rootRef = useRef();
  useEffect(() => {
    if (isMobile && rootRef.current) {
      return onOutsideClick(rootRef.current, () => {
        setIsOpen(false);
      });
    }
  });
  const topSuggestionName = inputItems[0]?.name;
  return (
    <div ref={rootRef}>
      <label {...getLabelProps()} style={{ display: "none" }}>
        Add an item
      </label>
      <Combobox {...getComboboxProps()}>
        <SearchIconSection>
          <SearchIcon>
            <i className="feather icon-search" />
          </SearchIcon>
        </SearchIconSection>
        <AddToBuyTextInput
          {...{ inputValue, topSuggestionName }}
          inputProps={getInputProps({ onFocus: open, onClick: open })}
        />
        {isMobile && (
          <ToggleButton onClick={() => setIsOpen((p) => !p)}>
            <i className={`feather icon-chevron-${isOpen ? "down" : "up"}`} />
          </ToggleButton>
        )}
      </Combobox>
      <OptionsList
        rootProps={getMenuProps()}
        {...{
          isOpen,
          inputItems,
          highlightedIndex,
          toBuy,
          getItemProps,
        }}
      />
    </div>
  );
};
