import React from "react";
import styled from "styled-components";
import { colours } from "../styles/colours";
import { iconButtonStyles } from "./Button.style";
import { EditItem } from "./EditItem";
import { onlyMobileWidth } from "./mediaStyle";

const Root = styled.div`
  color: ${colours.grey[8]};
  display: flex;
  align-items: center;
  background-color: ${colours.white};
  padding: 0.5rem;
  font-size: 1.2rem;
  max-width: 350px;
  ${onlyMobileWidth("max-width: none;")}
  margin-bottom: 1rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px ${colours.grey[5]};

  &:hover .edit-item {
    display: block;
  }
`;

const BoughtButton = styled.button`
  ${iconButtonStyles}
  margin-right: 2px;
  &:hover,
  &:active,
  &:focus {
    background-color: ${colours.positive[1]};
    color: ${colours.positive[9]};
  }
  &:active,
  &:focus {
    background-color: ${colours.positive[2]};
  }
`;

export const Item = ({ toBuy, item, items, _ref }) => {
  return (
    <Root>
      <BoughtButton
        onClick={() => {
          _ref.update({
            toBuy: toBuy.filter((id) => id !== item.id),
          });
        }}
      >
        <i className={`feather icon-check`} />
      </BoughtButton>
      {item.name}
      <EditItem {...{ item, items, toBuy, _ref }} />
    </Root>
  );
};
