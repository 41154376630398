import React from "react";
import styled from "styled-components";
import { colours } from "../styles/colours";
import { AddToBuyInput } from "./addToBuy/AddToBuyInput";
import { onlyMobileWidth, onlyTabletWidth } from "./mediaStyle";
import { ToBuyList } from "./ToBuyList";

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  ${onlyMobileWidth("flex-direction: column;")}
`;

const InputContainer = styled.div`
  width: 40%;
  ${onlyTabletWidth("width: 50%;")}
  ${onlyMobileWidth("width: 100%;")}
  box-sizing: border-box;
`;

const ListContainer = styled.div`
  width: 60%;
  ${onlyTabletWidth("width: 50%;")}
  ${onlyMobileWidth("width: 100%;")}

  padding: 32px;
  box-sizing: border-box;
  background-color: ${colours.grey[1]};

  box-shadow: inset 1px 1px 1px ${colours.grey[4]};
  flex-grow: 1;
`;

export const ListApp = ({ list }) => {
  return (
    <Root>
      <InputContainer>
        <AddToBuyInput {...list} />
      </InputContainer>
      <ListContainer>
        <ToBuyList {...list} />
      </ListContainer>
    </Root>
  );
};
