import React from "react";
import styled, { css } from "styled-components";
import { colours } from "../../styles/colours";
import { spacing } from "../../styles/spacing";
import { isIos } from "../../utils/isIos";
import { onlyMobileWidth } from "../mediaStyle";

window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
})

const Root = styled.ul`
  padding: 0;
  margin: 0;
  ${({ isOpen }) => (isOpen ? css`
    min-height: 30vh;
    overflow-y: auto;
    max-height: 87vh;
    ${isIos() ? 'min-height: calc(var(--vh, 1vh) * 30)':''};
  ` : "")};

  ${onlyMobileWidth(css`
    max-height: 60vh;
    ${isIos() ? 'max-height: calc(var(--vh, 1vh) * 60);':''}
    overflow-y: auto;
  `)}

  &::-webkit-scrollbar {
    background-color: white;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colours.grey[2]};
    border-radius: 8px;
  }
`;

export const Option = styled.li`
  color: ${colours.grey[7]};
  ${({ isHighlighted, alreadyInList }) => {
    if (isHighlighted && alreadyInList) {
      return css`
        color: ${colours.positive[8]};
        background-color: ${colours.positive[2]};
      `;
    } else if (isHighlighted) {
      return css`
        color: ${colours.grey[8]};
        background-color: ${colours.grey[1]};
      `;
    } else if (alreadyInList) {
      return css`
        color: ${colours.positive[9]};
        background-color: ${colours.positive[1]};
      `;
    }
  }}
  padding: 1rem;
  padding-left: ${spacing.leftKeyline};
  font-size: 1.2rem;
  cursor: pointer;
  list-style-type: none;
`;

export const OptionsList = ({
  inputItems,
  rootProps,
  isOpen,
  highlightedIndex,
  toBuy,
  getItemProps,
}) => {
  return (
    <Root {...{ isOpen, ...rootProps }}>
      {isOpen &&
        inputItems.map((item, index) => (
          <Option
            isHighlighted={index === highlightedIndex}
            alreadyInList={toBuy.includes(item.id)}
            key={`${item}${index}`}
            {...getItemProps({ item, index })}
          >
            {item.name || (
              <>
                <em>Create item: </em> {item.inputValue}
              </>
            )}
          </Option>
        ))}
    </Root>
  );
};
