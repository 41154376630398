import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { ListApp } from "./ListApp";
import { Layout } from "./Layout";

export const Home = ({ user }) => {
  const [list, setList] = useState();
  const router = useHistory();
  useEffect(() => {
    if (user === null) {
      router.push("/auth");
    }
  }, [user, router]);
  useEffect(() => {
    if (user) {
      firebase
        .firestore()
        .collection("lists")
        .where("owners", "array-contains", user.uid)
        .onSnapshot((results) => {
          const firstDoc = results.docs[0];
          if (firstDoc) {
            const data = firstDoc.data();
            return setList({
              ...data,
              toBuy: data.toBuy || [],
              _ref: firstDoc.ref,
            });
          }
          setList(null);
        });
    }
  }, [user]);

  if (!user) return null;

  return (
    <Layout {...{ user }}>
      {list && <ListApp {...{ list }} />}
      {list === null && "Please contact support"}
    </Layout>
  );
};
