import React, { useState } from "react";
import styled from "styled-components";
import { colours } from "../styles/colours";
import Modal from "react-modal";
import { iconButtonStyles, negativeButtonStyles } from "./Button.style";
import { onlyMobileWidth, onlyTouchScreen } from "./mediaStyle";
import TimeAgo from "timeago-react";

Modal.setAppElement(document.getElementById("root"));

const Root = styled.div`
  margin-left: auto;
  margin-right: 8px;
  display: none;
  ${onlyTouchScreen("display: block;")}
  ${onlyMobileWidth("display: block;")}
`;

const EditButton = styled.button`
  ${iconButtonStyles}
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
`;

const Title = styled.input`
  color: ${colours.grey[8]};
  font-size: 1.4rem;
  background: none;
  border: none;
  outline: none;
  padding: 8px;

  &:active,
  &:focus,
  &:hover {
    border-bottom: solid 1px ${colours.grey[4]};
  }
`;
const SmallInput = styled.input`
  color: ${colours.grey[7]};
  font-size: 1rem;
  background: none;
  border: none;
  outline: none;
  padding: 8px;

  &:active,
  &:focus,
  &:hover {
    border-bottom: solid 1px ${colours.grey[4]};
  }

  margin-top: 32px;
`;
const LastAdded = styled.div`
  color: ${colours.grey[7]};
  margin-top: 32px;
  padding: 8px;
`;
const DeleteButton = styled.button`
  ${negativeButtonStyles}
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px ${colours.negative[9]};
  margin-top: auto;
  align-self: flex-end;
`;

export const EditItem = ({ toBuy, item, items, _ref }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const close = () => setModalOpen(false);
  return (
    <Root className="edit-item">
      <EditButton onClick={() => setModalOpen(true)}>
        <i className={`feather icon-edit-`} />
      </EditButton>
      {isModalOpen && (
        <Modal
          isOpen={true}
          onRequestClose={close}
          style={{
            overlay: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            content: {
              display: "block",
              height: "250px",
              minHeight: "20%",
              width: 400,
              maxWidth: "80%",
              top: null,
              right: null,
              bottom: null,
              left: null,
            },
          }}
        >
          <ModalContent>
            <Title
              defaultValue={item.name}
              placeholder="Enter this item's name here"
              onChange={(e) => {
                _ref.update({
                  items: {
                    ...items,
                    [item.id]: {
                      ...item,
                      name: e.target.value,
                    },
                  },
                });
              }}
            />
            <SmallInput
              defaultValue={item.keyWords || ""}
              placeholder="Enter some keywords..."
              size={45}
              onChange={(e) => {
                _ref.update({
                  items: {
                    ...items,
                    [item.id]: {
                      ...item,
                      keyWords: e.target.value,
                    },
                  },
                });
              }}
            />
            {item.lastAddedAt && (
              <LastAdded>
                Added <TimeAgo datetime={item.lastAddedAt.toDate()} />{" "}
                by {item.lastAddedByEmail}
              </LastAdded>
            )}
            <DeleteButton
              onClick={() => {
                close();
                const { [item.id]: deletedItem, ...restItems } = items;
                const restToBuy = toBuy.filter((id) => id !== item.id);
                _ref.update({
                  items: restItems,
                  toBuy: restToBuy,
                });
              }}
            >
              Delete
            </DeleteButton>
          </ModalContent>
        </Modal>
      )}
    </Root>
  );
};
