import Fuse from "fuse.js";
import sortBy from "lodash.sortby";

export const sortItems = (items, toBuy) => {
  const withInListProp = items.map((i) => ({
    ...i,
    inList: toBuy.includes(i.id),
  }));

  return sortBy(withInListProp, "inList", "name");
};

const insert = (arr, index, newItem) => {
  index = index === -1 ? arr.length : index;
  return [...arr.slice(0, index), newItem, ...arr.slice(index)];
};

export const getOptions = ({ inputItems, inputValue, toBuy }) => {
  if (!inputValue) return sortItems(inputItems, toBuy);

  const itemSearch = new Fuse(inputItems, {
    keys: [{name: "name", weight: 5}, {name: "keyWords", weight: 1}],
    includeScore: true,
    threshold: 0.5,
    ignoreLocation: false
  }).search(inputValue);

  const createOption = {
    inputValue,
    id: "create",
  };

  if (!itemSearch[0]) {
    return [createOption];
  }

  console.log(
    `${(Math.round(itemSearch[0].score * 1000) / 1000).toFixed(
      3
    )} score for ${inputValue} against ${itemSearch[0].item.name}`
  );
  console.log(
    `${(
      Math.round(itemSearch[itemSearch.length - 1].score * 1000.0) / 1000
    ).toFixed(3)} score for ${inputValue} against ${
      itemSearch[itemSearch.length - 1].item.name
    }`
  );

  const items = itemSearch.map((s) => s.item);

  const hasExactMatch = items.some(i => i.name.toLowerCase() === inputValue.toLowerCase());
  if (hasExactMatch) {
    return items;
  }

  const hasVeryCloseMatch = itemSearch.some(s => s.score < 0.001)

  const insertCreateAt = hasVeryCloseMatch ? 1 : 0;
  return insert(items, insertCreateAt, createOption);
};
