import styled from "styled-components";
import { colours } from "../../styles/colours";

export const InputWrapper = styled.div`
  padding: 1rem 0;
  flex-grow: 1;
  height: 48px;
  position: relative;
  background: none;
`;

export const Input = styled.input`
  color: ${colours.grey[8]};
  font-family: "Metropolis-ExtraBold";
  padding: 0;
  font-size: 1.8rem;
  border: none;
  outline: none;
  background: none;
  position: absolute;
  width: 100%;
  height: 34px;
  top: 26px;
  left: 0;
  right: 0;
`;

export const InputOverlay = styled.div`
  color: ${colours.grey[5]};
  font-family: "Metropolis-ExtraBold";
  padding: 0;
  font-size: 1.8rem;
  border: none;
  outline: none;
  position: absolute;
  width: 100%;
  height: 34px;
  top: 29px;
  left: 0;
  right: 0;
  overflow: hidden;
  white-space: nowrap;
`;

export const HiddenChars = styled.span`
  color: white;
`;