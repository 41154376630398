import styled from "styled-components";
import { spacing } from "../../styles/spacing";
import { colours } from "../../styles/colours";

export const Combobox = styled.div`
  display: flex;
  font-size: 1.2rem;
`;

export const SearchIconSection = styled.div`
  display: flex;
  flex-shrink: 0;
  width: ${spacing.leftKeyline};
  flex-direction: row-reverse;
  align-items: center;
`;

export const SearchIcon = styled.div`
  i {
    font-size: 1.6rem;
  }
  display: flex;
  font-size: 1.2rem;
  color: ${colours.grey[4]};
  margin-right: 12px;
`;

export const ToggleButton = styled.button`
  i {
    font-size: 1.8rem;
  }
  color: ${colours.grey[7]};
  padding: 1rem 1rem 1rem 0;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
`;