import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/performance";
import { config } from "./fbConfig";

export const initializeFb = () => {
    if (!firebase.apps.length) {
        const app = firebase.initializeApp(config);
        firebase.analytics();
        firebase.performance();
        return app;
    }
    return firebase.app();
}