import React from "react";
import styled from "styled-components";

const Root = styled.div`
  color: hsl(220, 10%, 30%);
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  flex-grow: 1;
`;

const AppBar = styled.div`
  text-align: right;
  padding: 1rem;
`;

export const Layout = ({ user, children }) => {
  return (
    <Root>
      <AppBar>
        {user.email}
      </AppBar>
      <Content>
        {children}
      </Content>
    </Root>
  );
};
