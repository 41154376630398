const { css } = require("styled-components");
const { colours } = require("../styles/colours");

const buttonStyles = css`
  color: ${colours.grey[8]};
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  &:hover,
  &:active,
  &:focus {
    background-color: ${colours.primary[1]};
    color: ${colours.primary[9]};
    outline: none;
  }
  &:active,
  &:focus {
    background-color: ${colours.primary[2]};
  }
`;

export const negativeButtonStyles = css`
  ${buttonStyles}
  color: ${colours.negative[9]};
  &:hover,
  &:active,
  &:focus {
    background-color: ${colours.negative[1]};
    color: ${colours.negative[9]};
    outline: none;
  }
  &:active,
  &:focus {
    background-color: ${colours.negative[2]};
  }
`;

export const iconButtonStyles = css`
  ${buttonStyles}
  border-radius: 50%;
  padding: 6px;
`;
