export function onOutsideClick(element, callback) {
  const outsideClickListener = (event) => {
    if (!element.contains(event.target)) {
      callback();
      removeClickListener();
    }
  };

  const removeClickListener = () => {
    document.removeEventListener("click", outsideClickListener);
  };

  document.addEventListener("click", outsideClickListener);

  return removeClickListener;
}
