export const config = {
  apiKey: "AIzaSyB0713Q1uLoPDB_MYfUsr6FHA5tSa4II_o",
  authDomain: "anventory-prod.firebaseapp.com",
  databaseURL: "https://anventory-prod.firebaseio.com",
  projectId: "anventory-prod",
  storageBucket: "anventory-prod.appspot.com",
  messagingSenderId: "394491704190",
  appId: "1:394491704190:web:1bfa1785be14794208792f",
  measurementId: "G-8C6037452Y",
};
