import { useMediaQuery } from "react-responsive";

const { css } = require("styled-components");

const mobileWidth = "(max-width: 600px)";
const tabletWidth = "(max-width: 600px)";

export const onlyMobileWidth = styles => css`
  @media only screen and ${mobileWidth} {
    ${styles}
  }
`;

export const onlyTabletWidth = styles => css`
  @media only screen and ${tabletWidth}  {
    ${styles}
  }
`;

export const onlyTouchScreen = styles => css`
  @media only screen and (hover: none)  {
    ${styles}
  }
`;

export const useIsMobileWidth = () => useMediaQuery({ query: mobileWidth });