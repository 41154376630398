import uniqId from "uniqid";
import firebase from "firebase/app";

export const submitItem = async ({ items, toBuy, _ref, selectedItem }) => {
  const filteredToBuy = toBuy.filter((id) => id !== selectedItem.id);
  const updates = {};

  const {
    id,
    name = "",
    addedCount = "",
    keyWords = "",
    lastAdded = "",
    lastAddedByEmail = "",
    lastAddedById = "",
  } = selectedItem;
  let newItem = {
    id,
    name,
    keyWords,
    addedCount,
    lastAdded,
    lastAddedByEmail,
    lastAddedById,
  };
  const isNewItem = selectedItem.id === "create";
  if (isNewItem) {
    newItem = {
      id: uniqId(),
      name: selectedItem.inputValue,
    };
  }

  updates.toBuy = [newItem.id, ...filteredToBuy];

  const shouldLog = filteredToBuy.length === toBuy.length;
  if (shouldLog) {
    const userId = firebase.auth().currentUser.uid;
    const userEmail = firebase.auth().currentUser.email;
    const now = firebase.firestore.FieldValue.serverTimestamp();
    newItem.lastAddedAt = now;
    newItem.lastAddedByEmail = userEmail;
    newItem.lastAddedById = userId;
    newItem.addedCount = newItem.addedCount ? newItem.addedCount + 1 : 1;
    _ref.collection("logs").add({
      itemId: newItem.id,
      itemName: newItem.name,
      action: "add",
      isNew: isNewItem,
      byEmail: userEmail,
      byId: userId,
      at: now,
    });
  }

  updates.items = {
    ...items,
    [newItem.id]: newItem,
  };

  _ref.update(updates);
};
